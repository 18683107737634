exports.downloadApp = function () {
    var mobilePlatform = checkMobilePlatform();
    if (mobilePlatform === 'Android') {
       document.getElementById('playstore').click();
       //window.open('market://details?id=in.yournotebook.your_notebook');
    } else if (mobilePlatform === 'iOS') {
      document.getElementById('appstore').click();
        //window.open('https://apps.apple.com/in/app/your-notebook-stationery-app/id6448907646');
    } else {
        alert('You are not using an Android or iOS device.');
    }
}
function checkMobilePlatform() {
    var userAgent = navigator.userAgent;

    if (/Android/i.test(userAgent)) {
        return 'Android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return 'iOS';
    } else {
        return 'Unknown';
    }
}
