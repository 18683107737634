import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router, Event, NavigationEnd } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { Category, ProductDetail } from 'src/app/interfaces/home';
import { Paging, SearchResult } from 'src/app/interfaces/searchresult';
import { SortBy } from 'src/app/interfaces/sortby';
import { DataService } from 'src/app/services/data.service';
import { SearchService } from 'src/app/services/search.service';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy, AfterViewInit {

  public sort_by = [
    { text: 'Recommended', value: 'Recommended', sort_order: 'ASC' },
    { text: 'Whats New', value: 'New', sort_order: 'ASC' },
    { text: 'Better Discount', value: 'Discount', sort_order: 'DESC' },
    { text: 'Price: Hight to Low', value: 'Price', sort_order: 'DESC' },
    { text: 'Price: Low to High', value: 'Price', sort_order: 'ASC' },
    { text: 'Rating', value: 'AvgRating', sort_order: 'DESC' }
  ];

  public price_pattern = { 0: { pattern: new RegExp('\[1-9\]') } };
  public current_sortby_option = this.sort_by[0];
  public products: Array<ProductDetail> = [];
  public pagination: Paging = null;
  public categories: Array<Category> = [];
  public page_loading: boolean = false;
  public product_loading: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public current_page: number = 1;

  constructor(
    public _searchService: SearchService,
    private _dataService: DataService,
    private _offcanvasService: NgbOffcanvas,
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _toaster: ToastrService,
    private meta: Meta,
    private title: Title) { }

  ngOnInit(): void {

    this.page_loading = true;
    this.product_loading = true;
    let price_start = this._activatedRoute.snapshot.queryParams['price_start'];
    let price_end = this._activatedRoute.snapshot.queryParams['price_end'];
    let page = this._activatedRoute.snapshot.queryParams['page'];
    let q = this._activatedRoute.snapshot.queryParams['q'];
    let sort = this._activatedRoute.snapshot.queryParams['sort'];
    let cid = this._activatedRoute.snapshot.queryParams['cid'];
    let category = this._activatedRoute.snapshot.queryParams['category'];
    this._searchService.searchTerm.setValue(q ? q : '')
    this._searchService.cid = cid ? cid : 0;
    this._searchService.rating = this._activatedRoute.snapshot.queryParams['ratings'];
    this._searchService.price_start.setValue(price_start ? price_start : null);
    this._searchService.price_end.setValue(price_end ? price_end : null);
    this._searchService.product_id = this._activatedRoute.snapshot.queryParams['product_id'];
    this._searchService.category = category ? category : '';
    this._searchService.sort = sort ? sort : 'Discount';
    this._searchService.page = page ? page : 1;
    this._dataService.getCategories().toPromise().then((category) => {
      this.categories = category;
      this.applyFilter();
      this.page_loading = false;
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    });
    let metatagTitle = "";
    let metatagDescription = ""
    if (cid == 1) {
      metatagTitle = "Diary Notebooks | Notebooks & Diaries | Buy Diary & Notebooks Online | buy diaries notebooks online in Delhi ncr"
      metatagDescription = "Diary Notebooks  are organized and also stylish well furnished with our 2024 new brand, And its perfect for carrying in your bag or other things."
    }
    if (cid == 2) {

    }
    if (cid == 3) {

    }
    if (cid == 4) {

    }
    if (cid == 5) {

    }
    if (cid == 6) {

    }
    if (cid == 7) {

    }
    if (cid == 8) {

    }

    this.title.setTitle(metatagTitle); // <-- Update the title
    this.meta.updateTag({
      name: 'description',
      content: metatagDescription
    });
  }

  ngAfterViewInit(): void {
    this._activatedRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      setTimeout(() => {
        this.getSearchResult();
      }, 300);
    });
  }

  ngOnDestroy(): void {
    this._searchService.searchTerm.setValue('');
    this._searchService.cid = 0;
    this._searchService.category = '';
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  public setSearchRating(rating) {
    this._searchService.rating = rating;
    this.applyFilter();
  }

  public setSearchCategory(category: Category) {
    this._searchService.category = category.name;
    this._searchService.cid = category.id;
    this._searchService.page = 1;
    this.current_page = 1
    this.applyFilter();
  }

  public setSearchSortby(value: SortBy) {
    this.current_sortby_option = value;
    this._searchService.sort = value.value;
    this._searchService.sort_order = value.sort_order;
    this.applyFilter();
  }

  public applyFilter() {
    this._searchService.sort_order = this.current_sortby_option.sort_order;
    let queryParams: Params = {};
    if (this._searchService.searchTerm.value) {
      queryParams = { ...queryParams, q: this._searchService.searchTerm.value }
    }
    if (this._searchService.category) {
      queryParams = { ...queryParams, category: this._searchService.category }
    }
    if (this._searchService.cid) {
      queryParams = { ...queryParams, cid: this._searchService.cid }
    }
    if (this._searchService.rating) {
      queryParams = { ...queryParams, rating: this._searchService.rating }
    }
    if (this._searchService.price_start.value) {
      queryParams = { ...queryParams, price_start: this._searchService.price_start.value }
    }
    if (this._searchService.price_end.value) {
      queryParams = { ...queryParams, price_end: this._searchService.price_end.value }
    }
    if (this._searchService.product_id) {
      queryParams = { ...queryParams, product_id: this._searchService.product_id }
    }
    if (this._searchService.sort) {
      queryParams = { ...queryParams, sort: this._searchService.sort }
    }
    if (this._searchService.sort_order) {
      queryParams = { ...queryParams, sort_order: this._searchService.sort_order }
    }
    if (this._searchService.page) {
      queryParams = { ...queryParams, page: this._searchService.page }
    }
    this.setSortOrder();
    this._router.navigate(
      [],
      {
        relativeTo: this._activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: '', // remove to replace all query params by provided
      });
  }

  public openFilterLayout(content) {
    this._offcanvasService.open(content, { ariaLabelledBy: 'search-filter' });
  }

  public resetFilter() {
    this._searchService.price_end.setValue(null);
    this._searchService.price_start.setValue(null);
    this._searchService.rating = null;
    // this._searchService.sort = null;
    this._searchService.cid = 0;
    this._searchService.category = null;
    this._router.navigate(
      ['.'],
      {
        relativeTo: this._activatedRoute,
        queryParams: { q: this._searchService.searchTerm.value, category: this._searchService.category, cid: this._searchService.cid, sort_order: this._searchService.sort_order },
        queryParamsHandling: '', // remove to replace all query params by provided
      });
    this._searchService.searchResult().toPromise().then((result: SearchResult) => {
      this.products = result.data;
      this.pagination = result.paging;
      this.product_loading = false;
    });
    this._changeDetectorRef.markForCheck();
  }

  public changePage(page) {
    this.current_page = page;
    this._searchService.page = this.current_page;
    this.applyFilter();
  }

  /* set sort order */
  private setSortOrder() {
    let index = this.sort_by.findIndex((s) => { return s.value == this._searchService.sort && s.sort_order == this._searchService.sort_order });
    this.current_sortby_option = this.sort_by[index < 0 ? 0 : index];
  }

  /* Get Search Result */
  getSearchResult() {
    this.product_loading = true;
    this._searchService.searchResult().toPromise().then((result: SearchResult) => {
      this.products = result.data;
      this.pagination = result.paging;
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.product_loading = false;
    });
  }


}
