import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [NgbRatingConfig]
})
export class RatingComponent implements AfterViewInit {

  @Input('rating') public rating: number = 0;
  @Input('readOnly') public readOnly: boolean = true;
  @Input('control') control: AbstractControl;

  constructor(private config: NgbRatingConfig) { 
    config.max = 5;
  }

  ngAfterViewInit(): void {
    
  }

  onRateChange(event){
    if(this.control){
      this.control.setValue(event);
    }
  }

}
