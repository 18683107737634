import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { FaqsTypeComponent } from './pages/faqs-type/faqs-type.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HandleRequestInterceptor } from './interceptor/handle-request.interceptor';
import { DeliveryAddressesComponent } from './pages/delivery-addresses/delivery-addresses.component';
import { DeliveryAddressComponent } from './pages/delivery-address/delivery-address.component';
import { CartComponent } from './pages/cart/cart.component';
import { OrderSummaryComponent } from './pages/order-summary/order-summary.component';
import { OrderDetailsComponent } from './pages/order-details/order-details.component';
import { AuthService } from './auth/auth.service';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProductDetailsComponent } from './pages/product/product.component';
import { PaymentCallbackComponent } from './pages/payment-callback/payment-callback.component';
import { PaymentCancelComponent } from './pages/payment-cancel/payment-cancel.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ReturnComponent } from './pages/return/return.component';
import { TermsComponent } from './pages/terms/terms.component';
import { BaseComponent } from './shared/components/base/base.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReturnOrderComponent } from './pages/return-order/return-order.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { DownloadComponent } from './download/download.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqsComponent,
    FaqsTypeComponent,
    SearchResultComponent,
    DeliveryAddressesComponent,
    DeliveryAddressComponent,
    CartComponent,
    OrderSummaryComponent,
    OrderDetailsComponent,
    MyAccountComponent,
    ProfileComponent,
    AboutUsComponent,
    ContactComponent,
    ProductDetailsComponent,
    PaymentCallbackComponent,
    PaymentCancelComponent,
    PrivacyComponent,
    ReturnComponent,
    TermsComponent,
    BaseComponent,
    ReturnOrderComponent,
    CheckoutComponent,
    DownloadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxPaginationModule
  ],
  providers: [,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
