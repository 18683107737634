import { Component, Input, OnInit } from '@angular/core';
import { ProductDetail } from 'src/app/interfaces/home';
import { ShoppingCartResponse } from 'src/app/interfaces/shoppingCart';
import { DataService } from 'src/app/services/data.service';
import { getImagePath, getLocationCode } from 'src/app/functions/imagePath';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input('productDetail') productDetail: ProductDetail = null;
  public isLoading: boolean = false;

  constructor(private _dataService: DataService, private _toaster: ToastrService, private _router: Router) { }

  ngOnInit(): void {
  }

  get getPorudctImagePath(): string {
    return `${getImagePath()}${getLocationCode()}/product/wcdn/`;
  }

  public addToCart(productId: number){
    this.isLoading = true;
    this._dataService.addToCart({productId: productId, quantity: this.productDetail.minLimit}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res);
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private storeCart(cart: ShoppingCartResponse){
    localStorage.setItem('yournotebook.cart', JSON.stringify(cart));
  }

  get getProduct(){
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    if(cart && cart.cartItems){
      return cart.cartItems.find((item) => {
        return item.productId == this.productDetail.id;
      }); 
    } else {
      return null;
    }
  }

  public descreaseQuanity(){
    const product = this.getProduct;
    const quantity = product.minLimit == product.quantity ? product.quantity - product.minLimit : product.quantity-1;
    this.isLoading = true;
    this._dataService.patchCart({productId: this.productDetail.id, quantity: quantity}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res);
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public increaseQuanity(){
    this.isLoading = true;
    this._dataService.patchCart({productId: this.productDetail.id, quantity: this.getProduct.quantity+1}).toPromise().then((res: ShoppingCartResponse) => {
      this.storeCart(res);
    }).catch((ex) => {
      this._toaster.error(`Sorry, you can't add more of this item`, 'Error');
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public copyLink(){
    const url = this._router.serializeUrl(
      this._router.createUrlTree([`/product/${this.productDetail.id}`], { queryParams: {category: this.productDetail.category, name: this.productDetail.name}})
    );
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `${window.location.origin}${url}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._toaster.info(`Product url is copied`, 'Copied');
  }

  get getFormattedName(): string{
    return this.productDetail.name.replace(/\s+/g, '-').toString().replace(/---/g, '-');
  }

  get getFormattedCategory(): string {
    return this.productDetail.category.replace(/\s+/g, '-').toString().replace(/---/g, '-');
  }

}
