<ng-container *ngIf="!productLoading">
    <section class="cart my-3">
        <div class="container-xxl">
            <ng-container *ngIf="getCart?.cartItems && getCart?.cartItems.length > 0">
                <div class="d-flex flex-row justify-content-start align-items-center">
                    <h1 class="txt-black mb-0">My Cart</h1>
                    <div class="form-check ms-4">
                        <input class="form-check-input" type="checkbox" checked>
                        <label class="form-check-label">
                            {{getSelectedItems}}/{{getCart?.cartItems?.length}} items selected <span class="total">(Rs {{getCart.totalAmount}})</span>
                        </label>
                    </div>
                </div>
        
                <div class="cart-delivery-address-wrapper mt-2" *ngIf="_authService._authenticated">
                    <h2 class="txt-black">Delivery Address</h2>
        
                    <div class="mt-3 cart-delivery-address position-relative" *ngIf="getCart.address">
                        <div class="home-icon mt-1">
                            <img src="assets/images/home.svg" alt="home-icon">
                        </div>
                        <div class="address-text-wrapper d-flex flex-row align-items-lg-center">
                            <div class="">
                                <p class="mb-0 address-label">{{getCart.address.addressLine1}} {{getCart.address.addressLine2}}</p>
                            </div>
                            <button class="btn btn-primary change-address-btn ms-auto" (click)="openChangeAddressPopup(addressPopup)">
                                <img class="me-1" src="assets/images/pen.svg" alt="pen-icon">
                                Change Address
                            </button>
                        </div>
                    </div>
    
                    <ng-container *ngIf="!getCart.address">
                        <button class="btn btn-primary change-address-btn ms-auto" (click)="gotoAddAddress(loginModal)">
                            <img class="me-1" src="assets/images/pen.svg" alt="pen-icon">
                            Add Address
                        </button>
                    </ng-container>
                </div>

                <div class="mt-4 cart-info-wrapper">
                    <div class="row position-relative">
                        <div class="col-lg-7 col-12">
                            <!-- CART PRODUCT -->
                            <ng-container *ngFor="let product of getCart.cartItems; index as i">
                                <div class="card cart-product mb-4">
                                    <!-- flex-sm-row flex-column -->
                                    <div class="d-flex p-md-3 position-relative">
                                        <div class="position-relative">
                                            <div class="card-product-image-check position-absolute">
                                                <div class="form-check">
                                                    <input class="form-check-input" [checked]="product.isSelected" [name]="'selected'+i" type="checkbox" (change)="shoppingCartProduct(i)">
                                                </div>
                                            </div>
                                            <a class="position-relative" [routerLink]="['/product/'+product.productId]" [queryParams]="{ category: getFormatted(product.category), name: getFormatted(product.name) }">
                                                <img [src]="getPorudctImagePath+product.displayUrl" class="cart-product-image" [alt]="product.name">
                                            </a>
                                        </div>
                                        <div class="cart-product-info order-3 order-md-2 ms-3 ms-md-4 me-md-5 mt-md-0 mt-3">
                                            <a class="cart-product-name" [routerLink]="['/product/'+product.productId]" [queryParams]="{ category: getFormatted(product.category), name: getFormatted(product.name) }">{{product.name}}</a>
                                            <div class="d-flex align-items-center mb-1">
                                                <p class="cart-product-discount-price mb-0 me-2" *ngIf="product.discount">Rs {{product.unitPrice}}</p>
                                                <p class="cart-product-discounted-price mb-0">Rs {{product.discountedPrice}}</p>
                                                <p class="cart-product-discount mb-0 ms-2" *ngIf="product.discount">{{product.discount}}</p>
                                            </div>
                                            <div class="form-check send-gift" *ngIf="product.isGiftOption">
                                                <input class="form-check-input" type="checkbox" [checked]="product.isGift" [name]="'gift'+i" (change)="shoppingCartGift(i)">
                                                <label class="form-check-label">
                                                    Send as a gift. Include custom message
                                                </label>
                                            </div>
                                            <div class="btn-group cart-product-controls" role="group">
                                                <button type="button" class="btn px-3" (click)="descreaseQuanity(i)">-</button>
                                                <div class="btn">{{product.quantity}}</div>
                                                <button type="button" class="btn px-3 border-0" [disabled]="product.quantity >= product.maxLimit" (click)="increaseQuanity(i)">+</button>
                                            </div>
                                        </div>
                                        <button class="btn cross-btn position-absolute" (click)="removeProduct(product.productId)">
                                            <img src="assets/images/cross.svg" alt="cross">
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-lg-5 col-12">
                            <div class="tip-wrapper light-blue-card position-relative mb-4">
                                <div class="tip-icon position-absolute">
                                    <img src="assets/images/tip-icon.svg" alt="tip-icon">
                                </div>
                                <div class="ms-4">
                                    <h3 class="txt-black title">Tip your delivery partner</h3>
                                    <p class="sub-title">Your kindness means a lot! Thank your delivery partner them 
                                        with a tip. 100% of the tip will go to your delivery partner.</p>
                                    <div class="tip-valus-wrapper d-flex flex-wrap">
                                        <button class="btn tip-value" [ngClass]="{'active': tip_value == 10}" (click)="setTip(10)">
                                            <img src="assets/images/cookie.svg" alt="cookie">
                                            <span class="value">10</span>
                                        </button>
                                        <button class="btn tip-value" [ngClass]="{'active': tip_value == 20}" (click)="setTip(20)">
                                            <img src="assets/images/coffee.svg" alt="coffee">
                                            <span class="value">20</span>
                                        </button>
                                        <button class="btn tip-value" [ngClass]="{'active': tip_value == 30}" (click)="setTip(30)">
                                            <img src="assets/images/popcorn.svg" alt="popcorn">
                                            <span class="value">30</span>
                                        </button>
                                        <button class="btn tip-value" [ngClass]="{'active': tip_value == 50}" (click)="setTip(50)">
                                            <img src="assets/images/chocolate.svg" alt="chocolate">
                                            <span class="value">50</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
    
                            <div class="add-coupon-wrapper pointer light-blue-card p-3 d-flex mb-4" *ngIf="!toggleStatus && !getCart.couponApplied" (click)="toggleCoupon(loginModal)">
                                <div class="d-flex align-items-center flex-fill">
                                    <img src="assets/images/coupon.svg" alt="coupon" class="me-2">
                                    <p class="text mb-0">Add a coupon code</p>
                                </div>
                                <div>
                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div class="add-coupon-wrapper pointer light-blue-card p-3 d-flex mb-4" *ngIf="getCart?.couponApplied?.couponCode">
                                <div class="d-flex align-items-center flex-fill">
                                    <img src="assets/images/coupon.svg" alt="coupon" class="me-2">
                                    <p class="text mb-0"> Coupon Applied <b>{{getCart?.couponApplied?.couponCode}}</b></p>
                                </div>
                                <button type="button" class="btn custom-primary-btn" (click)="removeCoupon()">Remove</button>
                            </div>

                            <div class="add-coupon-wrapper pointer light-blue-card p-3 d-flex align-items-center mb-4" *ngIf="toggleStatus && !getCart.couponApplied">
                                <div class="form-field w-100 me-2">
                                    <input type="text" class="form-control max-h" [formControl]="couponControl" placeholder="Enter Coupon">
                                </div>
                                <div class="nick-name-wrapper">
                                    <button type="button" class="btn custom-primary-btn" [disabled]="couponControl.invalid" (click)="applyCoupon()">Apply</button>
                                </div>
                            </div>
    
                            <div *ngIf="getCart.message" class="free-delivery-info light-blue-card align-items-start p-3 d-flex mb-4">
                                <img src="assets/images/info-circle.svg" class="me-2" alt="info-circle">
                                <div [innerHTML]="getCart.message"></div>
                            </div>
    
                            <div class="cart-bill-info light-blue-card px-3 pt-4 pb-2 mb-2">
                                <div class="d-flex justify-content-between">
                                    <p class="bill-item">Item total</p>
                                    <p class="bill-item-value"><span class="text-decoration-line-through text-secondary" *ngIf="getCart.totalMRP > getCart.itemTotal">Rs {{getCart.totalMRP}}</span> Rs {{getCart.itemTotal}}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="bill-item">Delivery charge</p>
                                    <p class="bill-item-value" [ngClass]="{'text-decoration-line-through': getCart.deliveryCharges == getCart.deliveryDiscount}">Rs {{getCart.deliveryCharges}}</p>
                                </div>
                                <div class="d-flex justify-content-between" *ngIf="getCart.couponApplied">
                                    <p class="bill-item">Coupon discount</p>
                                    <p class="bill-item-value">Rs {{getCart.couponApplied.discount}}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="bill-item">Total saving</p>
                                    <p class="total-saving">Rs {{getCart.totalSaving}}</p>
                                </div>
                                <div class="hr"></div>
                                <div class="d-flex justify-content-between">
                                    <p class="grand-total">Grand total</p>
                                    <p class="total-price">Rs {{getCart.totalAmount}}</p>
                                </div>
                            </div>
    
                            <div class="d-grid">
                                <button class="btn btn-lg checkout" [disabled]="getCart.totalAmount == 0" (click)="checkout(loginModal)">Proceed to Buy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!getCart?.cartItems">
                <div class="my-5 py-5 text-center">
                    <div>
                        <i class="fa fa-cart-plus" style="font-size: 150px; color: #1a95bd;" aria-hidden="true"></i>
                    </div>
                    <p class="text-center mb-0 mt-5 font28-bold">You don't have any items in your cart</p>
                    <p class="m-0"><small>Your favourite items are just a click away</small></p>
                    <button class="btn mt-3 custom-primary-btn" (click)="gotoSearch()" routerLink="/search">
                        START SHOPPING
                    </button>
                </div>
            </ng-container>
        </div>
    </section>
    
    <section class="similar-product py-md-5 py-3" *ngIf="getCart?.cartItems">
        <div class="container-xxl">
            <h2 class="txt-black text-center title">You may also like</h2>
            <app-page-loader type="section" *ngIf="productLoading"></app-page-loader>
            <app-product-carousel [products]="similarProducts" *ngIf="!productLoading"></app-product-carousel>
        </div>
    </section>
</ng-container>

<div class="wating-loader" *ngIf="waitingLoader">
    <img src="assets/images/logo.png" height="50" class="logo" loading="lazy" alt="YourNotebook">
</div>

<app-page-loader *ngIf="productLoading"></app-page-loader>


<!-- Changes Address -->
<ng-template #addressPopup let-modal>
	<div class="modal-body login-modal position-relative py-4 px-lg-5">
        <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
        <div class="d-flex flex-column my-4">
            <div>
                <h1>Change Address</h1>
            </div>
        </div>
        <div class="text-center my-5" *ngIf="addressLoading">
            <div class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
        </div>
        <div class="text-end">
            <button class="btn custom-primary-btn" (click)="gotoAddAddress(loginModal)">Add New Address</button>
        </div>
        <div class="cart mt-3" *ngIf="!addressLoading">
            <div class="cart-delivery-address-wrapper">
                <ng-container *ngFor="let address of deliveryAddresses">
                    <div class="cart-delivery-address position-relative mb-3">
                        <div class="home-icon">
                            <img src="assets/images/home.svg" alt="home-icon">
                        </div>
                        <div class="address-text-wrapper d-flex flex-lg-row flex-column align-items-lg-center">
                            <div class="me-lg-5 mb-3 mb-lg-0">
                                <h3 class="txt-black">{{address.addressType}}</h3>
                                <p class="mb-0 address-label">{{address.addressLine1}} {{address.addressLine2}}</p>
                            </div>
                        </div>
                        <div class="text-end">
                            <button class="btn custom-primary-btn" (click)="changeAddress(address)">Select</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
	</div>
</ng-template>

<!-- LOGIN -->
<ng-template #loginModal let-modal>
	<div class="modal-body login-modal position-relative py-4 px-lg-5">
        <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
        <app-login></app-login>
	</div>
</ng-template>
