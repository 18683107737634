import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { DeliveryAddress, DeliveryAddressResponse } from 'src/app/interfaces/deliveryAddress';
import { ProductDetail } from 'src/app/interfaces/home';
import { CreateOrder } from 'src/app/interfaces/order';
import { ShoppingCartResponse } from 'src/app/interfaces/shoppingCart';
import { SimilarProduct } from 'src/app/interfaces/similarproducts';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { getImagePath, getLocationCode } from 'src/app/functions/imagePath';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { SearchService } from 'src/app/services/search.service';

declare var Razorpay;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  public productLoading: boolean = true;
  public similarProducts: Array<ProductDetail> = [];
  public deliveryAddresses: Array<DeliveryAddress> = [];
  public shoppingCartDetails: ShoppingCartResponse = null;
  public toggleStatus: boolean = false;
  public couponControl: FormControl = new FormControl(null, Validators.required);
  public waitingLoader: boolean = false;
  public addressLoading: boolean = false;
  public orderDetails: CreateOrder = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public tip_value: number = 0;
  constructor(
    private _dataService: DataService,
    public _authService: AuthService,
    private _router: Router,
    private _toaster: ToastrService,
    private _modalService: NgbModal,
    private _changeDetectorRef: ChangeDetectorRef,
    private _searchService: SearchService,
    private _ngZone: NgZone) { }

  ngOnInit(): void {
    this.shoppingCartDetails = this.getCart;
    this._authService._authStatusObservable.pipe(takeUntil(this._unsubscribeAll)).subscribe((auth) => {
      this.productLoading = true;
      this._dataService.getShoppingCart().toPromise().then((shoppingCart) => {
        this.shoppingCartDetails = shoppingCart;
        this.storeCart();
      }).catch((ex) => {
        this._toaster.error(ex.error.Message, 'Error');
      }).finally(() => {
        this.productLoading = false;
      });
    });
    this._dataService.getSimilarProducts(0).toPromise().then((res: SimilarProduct) => {
      this.similarProducts = res.result ? res.result : [];
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  get getCart(): ShoppingCartResponse {
    const cart: ShoppingCartResponse = JSON.parse(localStorage.getItem('yournotebook.cart'));
    return cart;
  }

  get getPorudctImagePath(): string {
    return `${getImagePath()}${getLocationCode()}/product/wcdn/`;
  }

  public descreaseQuanity(index){
    this.waitingLoader = true;
    const product = this.shoppingCartDetails.cartItems[index];
    // product.quantity--;
    this._dataService.patchCart({productId: product.productId, quantity: product.quantity-1}).toPromise().then((res: ShoppingCartResponse) => {
      this.shoppingCartDetails = res;
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    });
  }

  public increaseQuanity(index){
    const product = this.shoppingCartDetails.cartItems[index];
    if (product.quantity >= product.maxLimit) {
      this._toaster.error(`Sorry, you can't add more of this item`, 'Error');
    } else {
      this.waitingLoader = true;
      // product.quantity++;
      this._dataService.patchCart({productId: product.productId, quantity: product.quantity+1}).toPromise().then((res: ShoppingCartResponse) => {
        this.shoppingCartDetails = res;
        this.storeCart();
      }).catch(() => {
        this._toaster.error(`Sorry, you can't add more of this item`, 'Error');
      }).finally(() => {
        this.waitingLoader = false;
      }); 
    }
  }

  public shoppingCartGift(index){
    this.waitingLoader = true;
    const product = this.shoppingCartDetails.cartItems[index];
    this._dataService.shoppingCartGift({productId: product.productId, isSelcted: !product.isGift}).toPromise().then((result: ShoppingCartResponse) => {
      this.shoppingCartDetails = result;
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    });
  }

  public shoppingCartProduct(index){
    this.waitingLoader = true;
    const product = this.shoppingCartDetails.cartItems[index];
    this._dataService.shoppingCartProduct({productId: product.productId, isSelcted: !product.isSelected}).toPromise().then((result: ShoppingCartResponse) => {
      this.shoppingCartDetails = result;
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    });
  }

  get getSelectedItems(): number{
    if(this.getCart.cartItems){
      return this.getCart.cartItems.filter((item) => {
        return item.isSelected
      }).length;
    } else {
      return 0;
    }
  }

  public toggleCoupon(modalRef?){
    if(!this._authService._authenticated){
      this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true });
      this.waitingLoader = false;
    } else {
      this.toggleStatus = !this.toggleStatus;
    }
    
  }

  public applyCoupon(){
    this.waitingLoader = true;
    this._dataService.applyCoupon(this.couponControl.value).toPromise().then((res: ShoppingCartResponse) => {
      this.shoppingCartDetails = res;
      this.couponControl.reset();
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    })
  }

  public removeCoupon(){
    this.waitingLoader = true;
    this._dataService.removeCoupon(this.shoppingCartDetails.couponApplied.couponCode).toPromise().then((res: ShoppingCartResponse) => {
      this.shoppingCartDetails = res;
      this.toggleCoupon();
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    })
  }

  public removeProduct(id: number){
    this.waitingLoader = true;
    this._dataService.removeProduct(id).toPromise().then((res: ShoppingCartResponse) => {
      this.shoppingCartDetails = res;
      this.storeCart();
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    });
  }

  public openChangeAddressPopup(modalRef){
    this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true });
    this.addressLoading = true;
    this._dataService.getUserDeliveryAddress().toPromise().then((response: DeliveryAddressResponse) => {
      this.deliveryAddresses = response.data;
      this.storeCart();
      this.addressLoading = false;
    }).catch((ex) => {
      this._toaster.error(ex.error.Message, 'Error');
    }).finally(() => {
      this.waitingLoader = false;
    });
  }

  public changeAddress(address: DeliveryAddress){
    this.shoppingCartDetails.address = address;
    this.storeCart();
    this._modalService.dismissAll();
  }

  public gotoAddAddress(modalRef){
    if(this._authService.accessToken){
      this._modalService.dismissAll();
      this._router.navigate(['/delivery-address/new'], { queryParams: { redirect: 'cart' }})
    } else {
      this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true });
    }
  }

  public checkout(modalRef){
    this.shoppingCartDetails = this.getCart;
    this.waitingLoader = true;
    if(!this.shoppingCartDetails.address){
      if(this._authService._authenticated){
        this._toaster.info('Please add delivery address', 'Information');
        window.scrollTo({top: 0});
        this.waitingLoader = false;
      } else {
        this._modalService.open(modalRef, { ariaLabelledBy: 'modal-login', centered: true });
        this.waitingLoader = false;
      }
    } else {
      this._dataService.createOrder(this.shoppingCartDetails.address.id, this.tip_value).toPromise().then((res: CreateOrder) => {
        this.orderDetails = res;
        this._router.navigate(['checkout', res.paymentUri]);

        /** Razorpay Code start */
        /**
         * 
         * 
         *         let options = {
          key: environment.APIKey, // Enter the Key ID generated from the Dashboard
          amount: this.orderDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: this.orderDetails.name,
          description: this.orderDetails.description,
          image: this.orderDetails.logo,
          order_id: this.orderDetails.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          // callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
          prefill: {
              name: this.orderDetails.customerName,
              email: this.orderDetails.email,
              contact: this.orderDetails.phoneNumber
          },
          notes: {
              address: this.orderDetails.shippingAddress
          },
          theme: {
              color: '#1a95bd'
          },
          handler: (paymentSuccess) => {
            this._dataService.confirmOrder(paymentSuccess).toPromise().then((confirmOrder: any) => {
              this.waitingLoader = false;
              this._ngZone.run(() => {
                this._router.navigate(['/payment-callback/'+confirmOrder.result]);
                setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                }, 200);
              });
            })
          },
        }

        let rzp1 = new Razorpay(options);
        rzp1.open();
        rzp1.on('payment.failed', (paymentFailed) => {
          this._ngZone.run(() => {
            this.waitingLoader = false;
            rzp1.close();
            this._router.navigate(['/payment-cancel']);
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'));
            }, 200);
          })
        });
         */

        /** Razorpay Code END */
        // e.preventDefault();
      }).catch((ex) => {
        this._toaster.error(ex.error.Message, 'Error');
      }).finally(() => {
        this.waitingLoader = false;
      });
    }
  }

  /* Set Tip */
  public setTip(number){
    this.tip_value = number;
  }

  private storeCart(){
    localStorage.setItem('yournotebook.cart', JSON.stringify(this.shoppingCartDetails));
  }

  getFormatted(str: string): string{
    return str.replace(/\s+/g, '-').toString().replace(/---/g, '-').toLowerCase();
  }

  /* search */
  public gotoSearch(){
    this._router.navigate(['search'], { queryParams: { q: this._searchService.searchTerm.value , cid: this._searchService.cid, product_id: this._searchService.product_id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: '' });
  }

}
