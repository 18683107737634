<section class="delivery-address my-3">
    <div class="container-xxl">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div>
                <h2 class="txt-black section-title mb-0">Personal Details</h2>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Full Name <span class="bold text-danger">*</span></label>
                        <input type="text" maxlength="150" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('fullName').invalid && showFormError }" formControlName="fullName" placeholder="Full Name">
                        <p *ngIf="form.get('fullName').touched && form.get('fullName').hasError('maxlength')" class="text-danger"><small>Maximum 150 characters allowed</small></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Phone Number <span class="bold text-danger">*</span></label>
                        <input mask="0000000000" maxlength="10" type="text" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('phoneNumber').invalid && showFormError }" formControlName="phoneNumber" placeholder="xxxxxxxxxx">
                        <p *ngIf="form.get('phoneNumber').touched && form.get('phoneNumber').hasError('maxlength')" class="text-danger"><small>Maximum 10 digits allowed</small></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Email</label>
                        <input maxlength="50" type="email" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('email').invalid && showFormError }" formControlName="email" placeholder="Email">
                        <p *ngIf="form.get('email').touched && form.get('email').hasError('maxlength')" class="text-danger"><small>Maximum 50 characters allowed</small></p>
                    </div>
                </div>
            </div>

            <div>
                <h2 class="txt-black section-title mb-0">Address Details</h2>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Address 1 <span class="bold text-danger">*</span></label>
                        <textarea maxlength="150" cols="10" rows="4" class="form-control max-w" [ngClass]="{'invalid': form.get('addressLine1').invalid && showFormError }" formControlName="addressLine1" placeholder="Flat, House no., Building, Company, Apartment"></textarea>
                        <p *ngIf="form.get('addressLine1').touched && form.get('addressLine1').hasError('maxlength')" class="text-danger"><small>Maximum 150 characters allowed</small></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Address 2 <span class="bold text-danger">*</span></label>
                        <textarea maxlength="150" cols="10" rows="4" class="form-control max-w" [ngClass]="{'invalid': form.get('addressLine2').invalid && showFormError }" formControlName="addressLine2" placeholder="Area, Street, Sector, Village"></textarea>
                        <p *ngIf="form.get('addressLine2').touched && form.get('addressLine2').hasError('maxlength')" class="text-danger"><small>Maximum 150 characters allowed</small></p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Town/City/District <span class="bold text-danger">*</span></label>
                        <select class="form-select max-h max-w" [ngClass]="{'invalid': form.get('cityId').invalid && showFormError }" formControlName="cityId">
                            <option [value]="null">-- Select option --</option>
                            <ng-container *ngFor="let city of cities">
                                <option [value]="city.id">{{city.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">State <span class="bold text-danger">*</span></label>
                        <select class="form-select max-h max-w" [ngClass]="{'invalid': form.get('stateId').invalid && showFormError }" formControlName="stateId">
                            <option [value]="null">-- Select option --</option>
                            <ng-container *ngFor="let state of states">
                                <option [value]="state.id">{{state.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Pin Code <span class="bold text-danger">*</span></label>
                        <input type="email" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('pin').invalid && showFormError }" formControlName="pin" readonly>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="mb-3 form-field">
                        <label class="form-label">Landmark</label>
                        <input maxlength="50" type="email" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('landmark').invalid && showFormError }" formControlName="landmark">
                        <p *ngIf="form.get('landmark').touched && form.get('addressLine2').hasError('landmark')" class="text-danger"><small>Maximum 50 characters allowed</small></p>
                    </div>
                </div>
            </div>

            <div>
                <h2 class="txt-black section-title mb-0">Choose a Address Type <span class="bold text-danger">*</span></h2>
            </div>
            <div class="d-flex nick-name-wrapper flex-wrap mt-2" *ngIf="!toggleNickname">
                <button type="button" class="btn nick-btn me-3" [ngClass]="{'active': form.value.addressType == 'Home'}" (click)="setAddressType('Home')">Home</button>
                <button type="button" class="btn nick-btn me-3" [ngClass]="{'active': form.value.addressType == 'Office'}" (click)="setAddressType('Office')">Office</button>
                <button type="button" (click)="toggleNick()" class="btn nick-btn me-3">Other</button>
            </div>
            <div *ngIf="toggleNickname">
                <div class="mb-3 form-field">
                    <input type="text" class="form-control max-w max-h" [ngClass]="{'invalid': form.get('addressType').invalid && showFormError }" formControlName="addressType" placeholder="ABC">
                </div>
                <div class="nick-name-wrapper">
                    <button type="button" class="btn nick-btn" (click)="toggleNick()">Cancel</button>
                </div>
            </div>
            <div class="form-check my-4">
                <input class="form-check-input" id="isDefault" type="checkbox" formControlName="isDefault">
                <label class="form-check-label ms-1" for="isDefault">
                    make this as my default delivery address
                </label>
            </div>
            <div class="mt-4 text-center">
                <button class="btn submit custom-primary-btn" type="submit">Submit</button>
            </div>
        </form>
    </div>
</section>