import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-payment-callback',
  templateUrl: './payment-callback.component.html',
  styleUrls: ['./payment-callback.component.scss']
})
export class PaymentCallbackComponent implements OnInit {

  public orderId: string;
  public status: string;
  public loading: boolean = true;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _dataService: DataService,
    private _searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.orderId = this._activatedRoute.snapshot.paramMap.get('id');
    this._dataService.paymentStatus(this.orderId).subscribe((res) => {
      this.status = res.status.toLowerCase();
      this.loading = false;
    })

    this._dataService.getShoppingCart().subscribe((res) => {
      localStorage.setItem('yournotebook.cart', JSON.stringify(res));
    });
    this._dataService.getNotification();
  }

  /* goto home */
  public gotoHome(){
    this._router.navigate(['search'], { queryParams: { q: this._searchService.searchTerm.value , cid: this._searchService.cid, product_id: this._searchService.product_id, sort: 'Discount', sort_order: 'ASC', page: 1 }, queryParamsHandling: '' });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

}
