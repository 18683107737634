<section class="contact--banner py-5">
    <div class="container-xxl py-md-3">
        <div class="row py-md-3">
            <div class="col-12 text-center">
                <h1><span>Get in touch</span><br /> with us</h1>
            </div>
        </div>
    </div>
</section>
<section class="contact--info py-5">
    <div class="container-xxl py-md-5">
        <div class="row contact--info_row mx-1">
            <div class="col-12 col-lg-5 contact--info__wrapper p-4 p-md-5">
                <h5>CONTACT US</h5>
                <h2>Contact Information</h2>
                <p>Thank you for visiting our website! We value your feedback and are here to help with any questions or concerns you may have</p>
                <ul>
                    <li>
                        <i class="fa fa-map-marker" aria-hidden="true"></i>
                        <div>
                            <h6>Our Location</h6>
                            <p>P-101 Sector 11, Noida, Uttar Pradesh, India Pin - 201301</p>
                        </div>
                    </li>
                    <li>
                        <i class="fa fa-phone" aria-hidden="true"></i>
                        <div>
                            <h6>Our Number</h6>
                            <p><a href="tel:(+9172)49384189">(+9172)49384189</a></p>
                        </div>
                    </li>
                    <li>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <div>
                            <h6>Our Mail</h6>
                            <p><a href="mailto:contactus@yournotebook.in">contactus@yournotebook.in</a></p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-7 p-4 p-md-5 contact--info__form">
                <h2 class="mb-4">Send us an email</h2>
                <form [formGroup]="form" (ngSubmit)="submit(message)">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-floating mb-4">
                                <input type="text" class="form-control border-0 rounded-0" formControlName="name" id="name" placeholder="Your Name">
                                <label for="name">Your Name</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-floating mb-4">
                                <input type="email" class="form-control border-0 rounded-0" formControlName="email" id="email" placeholder="Your Email">
                                <label for="email">Email address</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-4">
                                <input type="tel" class="form-control border-0 rounded-0" formControlName="phone" id="phone" placeholder="Phone Number">
                                <label for="phone">Phone Number</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-4">
                                <textarea class="form-control border-0 rounded-0" formControlName="message" placeholder="Message Here" id="message" style="height: 150px"></textarea>
                                <label for="message">Message Here</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary contact--info__form--submit rounded-pill">SUBMIT YOUR REQUEST</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<ng-template #message let-modal>
    <div class="modal-body login-modal position-relative py-4 px-lg-5">
      <button type="button" class="btn-close modal-btn-close" (click)="modal.dismiss('Cross click')"></button>
      <div class="text-center mt-4">
        <img src="assets/images/message-send.png" class="my-2" alt="checkmark">
        <h1>Thank you!</h1>
        <p class="mb-3">Your submission has been sent.</p>
        <p class="px-3">A member of our sales team will review your request and get back to you as soon as possible</p>
      </div>
    </div>
  </ng-template>
  