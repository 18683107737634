<section class="delivery-addresses my-3">
    <div class="container-xxl">
        <div>
            <h1 class="txt-black mb-0">Delivery Address</h1>
            <p class="text-dark sub-title mb-0">Select a saved delivery address or add a new address</p>
        </div>

        <app-page-loader *ngIf="pageLoading"></app-page-loader>

        <div class="row" *ngIf="!pageLoading">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-3">
                <div class="pointer address-column d-flex flex-column justify-content-center align-items-center"
                [routerLink]="'/delivery-address/new'">
                    <div class="d-flex">
                        <div>
                            <img src="assets/images/address-icon.svg" alt="address-icon">
                        </div>
                        <div class="ms-3">
                            <p class="new-address-label">Add new address</p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let address of deliveryAddresses">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-3">
                    <div class="p-4 address-column position-relative">
                        <div class="address-icon">
                            <img src="assets/images/address-icon.svg" loading="lazy" alt="address-icon">
                        </div>
                        <div class="address-text-wrapper ms-5">
                            <h3 class="txt-black">{{address.addressType}}</h3>
                            <p class="mb-0 address-label">{{address.addressLine1}} {{address.addressLine2}}</p>
                            <div class="d-flex align-items-center">
                                <i class="fa fa-user-circle-o me-2" aria-hidden="true"></i>
                                <p class="mb-0 me-2">{{address.fullName}}</p>
                                <p class="mb-0">{{address.phoneNumber}}</p>
                            </div>
                        </div>
                        <div class="text-end mt-3">
                            <button class="btn btn-sm btn-info text-light me-2" [routerLink]="'/delivery-address/'+address.id">Edit</button>
                            <button class="btn btn-sm btn-danger" (click)="deleteAddress(confirm, address.id)">Delete</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #confirm let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
        <p>Are you sure you want to delete this address?</p>
	</div>
	<div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close(true)">Yes</button>
		<button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Cancel</button>
	</div>
</ng-template>