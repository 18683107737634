import { AfterViewInit, Component, OnInit } from '@angular/core';
import {downloadApp} from '../../../common.js'
@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
   
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      downloadApp();
  }, 500); 
  }
}
